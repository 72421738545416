/* eslint-disable prefer-const, object-shorthand, wrap-iife, import/prefer-default-export, curly, nonblock-statement-body-position */
/*
 *    Copyright © 2020, Mirinae Corp. and John Wainwright
 */

// auth API, provides user authentification & login services

import { createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { httpAPI } from './http';
import { apiPaths } from '@mirinae/shared/defines/paths';

import { Base64Encode, replaceAll } from 'apps/shared/libs/js-utils';
import { cookieDomain } from '@mirinae/js-utils/utils/links';
import { langsSelectList } from '@mirinae/shared/il8n';

// the login context, carries current logged-in user, etc.
export const UserContext = createContext();

const defaultLang = navigator.language.replace(/-\w+.*/, ''); // default to the browser language for this user, stripping off any script subtag

export const userInit = { // default prefs
    name: '',
    preferences: {
        language: langsSelectList[defaultLang] ? defaultLang : 'en',
        analysis: {
            mode: 'normal',
            fontSize: 'normal',
            speed: 'normal',
            gender: 'female',
            romanization: true,
        },
        lessonsLevel: 'Starter',
        useNewLesson: 'New',
    }
};

console.log('react-auth: userInit', userInit.preferences.language);

export function useUserState() {
    // set up user context using the auth SDK.  This becomes a global context with user,
    //   login-status, user-prefs, etc. that can be used anywhere in this app via useContext(UserContext)
    const userState = useState({
        user: { ...userInit },
        homePath: '/', // save cookie location
    });
    const cookieState = useCookies([]);

    useEffect(() => {
        console.log('in useUserState, call authAPI.setupUserContext');
        // console.log('   useUserState useEffect - lang', userState.user?.preferences?.language);
        authAPI.setupUserContext(userState, cookieState);
    }, []);
    //
    return userState;
}

// the auth API constructor
export const AuthAPI = (() => {

    // auth API constructor
    function AuthAPIConstructor(host) {
        // client instance vars
        this.apiHost = host || '';
        this.user = { };
        this.loadingUser = false;

        // These pages has to redirect home after login success
        this.redirectPage = {
            login: '/login',
            signup: '/signup',
            resetPassword: '/reset-password',
        };
    }

    AuthAPIConstructor.prototype = {

        setupUserContext: async function (userState, cookieState) {
            console.log('setupUserContext()', this.loadingUser);
            // remember useful state accessors
            if (this.loadingUser)
                return;
            this.loadingUser = true;

            [this.userContext, this.setUserContext] = userState;
            [this.cookies, this.setCookie, this.removeCookie] = cookieState;
            this.noCookies = this.cookies;

            // confirm login status
            const option = {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            };
            console.log('setupUserContext call /check', this.loadingUser);
            httpAPI(this.apiHost, apiPaths.check, option)
                .then(response => {
                    console.log('  /check response', response);
                    const { authenticated, user } = response.data;
                    if (!authenticated) {
                        console.log('  not authed, create guest acc', response);
                        // not logged in as either guest or registered user, auto-login as guest so we always have a user object
                        // create a temp guest user, function returns a Promise resolving to the new user object
                        httpAPI(this.apiHost, apiPaths.guestSignup, { method: 'GET' })
                            .then(response => {
                                const { user } = response.data;
                                if (user.preferences && !user.preferences.analysis) {
                                    // repair corrupt prefs
                                    user.preferences.analysis = { ...userInit.preferences.analysis };
                                }
                                this.setUserContext(prev => ({ ...prev, user: { ...userInit, ...user } }));
                                this.user._id = user._id;
                                console.log(`User ${this.user.name} logged in as new guest, id=${this.user._id}`);
                                this.loadingUser = false;
                                // let Flutter know if present
                                if (window.ProfileChannel) {
                                    window.ProfileChannel.postMessage(JSON.stringify({ command: 'loginAsGuest', value: user._id })); // webview
                                } else if (window.flutter_inappwebview) {
                                    window.flutter_inappwebview.callHandler('ProfileChannel', { command: 'loginAsGuest', value: user._id });
                                }
                                return user;
                            });
                    } else {
                        // update UserContext with full user data & other cookie-recorded prefs
                        this.setUserContext(prev => ({ ...prev, user: { ...userInit, ...user } }));
                        this.user._id = user._id;
                        console.log(`User ${user.name} logged in via cookies, id=${user._id}`);
                        this.setCookie('name', user.name, { path: this.userContext.homePath || '/', domain: cookieDomain });
                        if (user.loginType === 'guest') {
                            // let Flutter know about guest checks, if present
                            if (window.ProfileChannel) {
                                window.ProfileChannel.postMessage(JSON.stringify({ command: 'loginAsGuest', value: user._id })); // webview
                            } else if (window.flutter_inappwebview) {
                                window.flutter_inappwebview.callHandler('ProfileChannel', { command: 'loginAsGuest', value: user._id });
                            }
                        }
                        this.loadingUser = false;
                    }
                });

            // userState becomes the context so updates by nested components get re-rendered
            return userState;
        },

        firstTimeVisitor: function() {

        },

        setHistory: function (history) { this.history = history; },

        guestSignup: function () {
            // create a temp guest user, function returns a Promise resolving to the new user object
            return httpAPI(this.apiHost, apiPaths.guestSignup, { method: 'GET' })
                .then(response => {
                    const { user } = response.data;
                    this.setUserContext(prev => ({ ...prev, user: { ...userInit, ...user } }));
                    this.user._id = user._id;
                    console.log(`User ${this.user.name} logged in as new guest, id=${this.user._id}`);
                    this.loadingUser = false;
                    return user;
                });
        },

        // handle login success, set cookie name (and email when remember is true)
        loginSucceeded: function (user, error, remember, _homePath, hasRedirect, onFailure) {
            if (error) {
                // login failed, possibly existing account with same email or deleted account access
                // call onFailure handler
                onFailure(error);
                return;
            }
            //
            this.setCookie('name', user.name, { path: _homePath, domain: cookieDomain });
            // ensure we are marked as having visited before
            this.setCookie('visited', 'true', { path: _homePath, maxAge: 10 * 365 * 24 * 60 * 60, domain: cookieDomain });
            this.setCookie('agreed', 'true', { path: _homePath, maxAge: 10 * 365 * 24 * 60 * 60, domain: cookieDomain });
            // grab any available user details
            if (user.imageUrl) {
                this.setCookie('imageUrl', user.imageUrl, { path: _homePath, domain: cookieDomain });
                this.setUserContext(prev => ({ ...prev, imageUrl: user.imageUrl }));
            }
            //
            if (remember) {
                const expires = new Date();
                expires.setDate(expires.getDate() + 10);
                this.setCookie('email', user.email, { path: _homePath, expires, domain: cookieDomain });
            }
            // record in UserContext
            this.setUserContext(prev => ({ ...prev, user: { ...userInit, ...user } }));
            this.user._id = user._id;
            
            // if (hasRedirect && this.history.location.pathname === this.redirectPage.login
            //     || this.history.location.pathname === this.redirectPage.signup
            //     || this.history.location.pathname === this.redirectPage.resetPassword) {
            //         console.log('move to homePath:', _homePath)
            //     this.navigate(_homePath);
            // }
        },

        // handle error during login process
        handleAuthenticationError: function (error) {
            // TODO
            console.log(error);
        },

        // call local login api
        loginLocal: function (formData) {
            return new Promise((resolve, reject) => {
                const body = {
                    email: formData.email,
                    password: Base64Encode(formData.password),
                    // name: formData.userName,
                };
                // login
                httpAPI('', apiPaths.localLogin, { data: body })
                    .then((response) => { resolve(response); })
                    .catch(e => { reject(e); });
            });
        },

        // call sign up api
        signupLocal: function(formData) {
            return new Promise((resolve, reject) => {
                const body = {
                    email: formData.email,
                    password: Base64Encode(formData.password),
                    name: formData.userName,
                    promotion: formData.promotion
                };

                httpAPI('', apiPaths.localSignup, { data: body })
                    .then((response) => { resolve(response); })
                    .catch(e => { reject(e); });
            });
        },

        // google login onSuccess callback function
        responseGoogle: async function (_data, _remember, _homePath, hasRedirect = true, onFailure, promotion) {
            const body = {
                access_token: _data.accessToken,
                profile: _data.profileObj,
                promotion: promotion,
            };

            try {
                const response = await httpAPI('', apiPaths.googleLogin, { data: body });
                console.log(response);
                const { data: { user } = { }, error } = response;
                this.loginSucceeded(user, error, _remember, _homePath, hasRedirect, onFailure);
            } catch (e) {
                this.handleError(e);
            }
        },

        // facebook login onCompleted callback function
        responseFacebook: async function (_data, _remember, _homePath, hasRedirect = true, onFailure, promotion) {
            const body = {
                access_token: _data.tokenDetail.accessToken,
                profile: _data.profile,
                promotion: promotion,
            };

            try {
                const response = await httpAPI('', apiPaths.facebookLogin, { data: body })
                const { data: { user } = { }, error } = response;
                this.loginSucceeded(user, error, _remember, _homePath, hasRedirect, onFailure);
            } catch (e) {
                this.handleError(e);
            }
        },

        // twitter login onCompleted callback function
        responseTwitter: async function (_data, _remember, _homePath, hasRedirect = true, onFailure, promotion) {
            const item = replaceAll(_data, '"', '').replace('{', '').replace('}', '').split(',');
            const profile = {
                id: item[1].split(':')[1],
                name: item[2].split(':')[1],
                email: item[3].split(':')[1],
            };
            const body = {
                access_token: item[0].split(':')[1],
                profile: profile,
                promotion: promotion,
            };

            try {
                console.log('have response from twitter');
                const response = await httpAPI('', apiPaths.twitterLogin, { data: body })
                console.log('apiPaths.twitterLogin', response);
                const { data: { user } = { }, error } = response;
                this.loginSucceeded(user, error, _remember, _homePath, hasRedirect, onFailure);
            } catch (e) {
                this.handleError(e);
            }
        },

        // google login onSuccess callback function
        responseApple: async function (_data, _remember, _homePath, hasRedirect = true, onFailure, promotion) {
            const body = {
                token: _data.id_token,
                promotion: promotion,
            };

            try {
                console.log('have response from Apple, sending to', apiPaths.appleLogin);
                const response = await httpAPI('', apiPaths.appleLogin, { data: body })
                console.log('apiPaths.appleLogin', response);
                const { user = { }, error } = response;
                this.loginSucceeded(user, error, _remember, _homePath, hasRedirect, onFailure);
            } catch (e) {
                this.handleError(e);
            }
        },

        logout: function(_homePath) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPaths.logout, { method: 'GET' })
                    .then((response) => {
                        if (response.success) {
                            ['imageUrl'].forEach(c => this.removeCookie(c, { path: _homePath }));
                            this.removeCookie('name');
                
                            this.userContext.user = { ...userInit };
                            delete this.userContext.imageUrl;
                            this.setUserContext(prev => ({ ...prev, ...this.userContext }));
                        }
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },

        resetSendMail: function (_data) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPaths.sendMail, { data: _data })
                    .then((response) => { resolve(response); })
                    .catch(e => { reject(e); });
            });
        },
        resetVerifyOpt: function (_data) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPaths.verifyOpt, { data: _data })
                    .then((response) => { resolve(response); })
                    .catch(e => { reject(e); });
            });
        },
        resetPassword: function (_data) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPaths.reset, { data: _data })
                    .then((response) => { resolve(response); })
                    .catch(e => { reject(e); });
            });
        },

        deleteAccount: function(_homePath) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPaths.deleteAccount, { method: 'GET' })
                    .then((response) => {
                        if (response.success) {
                            ['imageUrl'].forEach(c => this.removeCookie(c, { path: _homePath }));
                            this.removeCookie('name');
                
                            this.userContext.user = { ...userInit };
                            delete this.userContext.imageUrl;
                            this.setUserContext(prev => ({ ...prev, ...this.userContext }));
                        }

                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },

        deleteAccountWithSurvey: function(_data, _homePath) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPaths.deleteAccount, { data: _data })
                    .then((response) => {
                        if (response.success) {
                            ['imageUrl'].forEach(c => this.removeCookie(c, { path: _homePath }));
                            this.removeCookie('name');
                
                            this.userContext.user = { ...userInit };
                            delete this.userContext.imageUrl;
                            this.setUserContext(prev => ({ ...prev, ...this.userContext }));
                        }
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },

        handleError: function(err) {
            console.log('handleError', err);
        },

        updatePrefs: function(_data) {
            const data = { prefUpdate: { ..._data } };

            return new Promise((resolve, reject) => {
                httpAPI('', apiPaths.updatePrefs, { data })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },

        checkLocalEmail: function(email) {
            return new Promise((resolve, reject) => {
                httpAPI('', `${apiPaths.checkLocalEmail}/${encodeURIComponent(email)}`, { method: 'GET' })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
    };

    // export an API instance constructor, takes an options host address for the API calls
    return AuthAPIConstructor;
})();

// an instance same host as SPA
export const authAPI = new AuthAPI();
